import $ from 'jquery';

function validateEmail(mail) 
{
  return (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail));
}

function initializeReserver() {
  $('.reserver').on('click', '.slot', function() {
    $('.reserver .slot').removeClass('active');
    $(this).addClass('active');
    $('#reservation_slot_id').val($(this).data('id').split('_')[1]);

    var end = parseInt($(this).data('end'));

    $('.reserver .pickup_times').show();
    $('.reserver .pickup_times .times .time').removeClass('active');
    $('.reserver .pickup_times .times .time').each(function() {
      var time = parseInt($(this).data('time'));

      if (time >= end) {
        $(this).show();
      } else {
        $(this).hide();
      }
    });
    $('#reservation_latest_pickup_time').val('');
  });

  $('.reserver .pickup_times .times').on('click', '.time', function() {
    $('.reserver .pickup_times .times .time').removeClass('active');
    $('#reservation_latest_pickup_time').val($(this).data('value'));
    $(this).addClass('active');
  });

  $(document).on('submit', '#reserve_form', function(e) {
    var cancel = false;

    // validate first name
    var name = $('#reservation_first_name').val();
    if (name == "" || name.length < 2) {
      $('#reservation_first_name').closest('.form-group').addClass('field_with_errors');
      if (!cancel) {
        $("body,html").animate(
          {
            scrollTop: $('#reservation_first_name').closest('.form-group').offset().top
          },
          800
        );
      }
      cancel = true;
    } else {
      $('#reservation_first_name').closest('.form-group').removeClass('field_with_errors');
    }

    // validate last name
    var name = $('#reservation_last_name').val();
    if (name == "" || name.length < 2) {
      $('#reservation_last_name').closest('.form-group').addClass('field_with_errors');
      if (!cancel) {
        $("body,html").animate(
          {
            scrollTop: $('#reservation_last_name').closest('.form-group').offset().top
          },
          800
        );
      }
      cancel = true;
    } else {
      $('#reservation_last_name').closest('.form-group').removeClass('field_with_errors');
    }

    // validate email
    var email = $('#reservation_email').val().replace(/ /g, '');
    $('#reservation_email').val(email);
    if (!/^\w+([\.-]?\w+)*@cez\.cz/.test(email)) {
      $('#reservation_email').closest('.form-group').addClass('field_with_errors');
      if (!cancel) {
        $("body,html").animate(
          {
            scrollTop: $('#reservation_email').closest('.form-group').offset().top
          },
          800
        );
      }
      cancel = true;
    } else {
      $('#reservation_email').closest('.form-group').removeClass('field_with_errors');
    }

    // validate phone number
    var phoneNumber = $('#reservation_phone').val().replace(/ /g, '');
    $('#reservation_phone').val(phoneNumber);
    if (phoneNumber == "" || phoneNumber.length < 9 || !/^\+([0-9]){12}|00([0-9]){12}|([0-9]){9}/.test(phoneNumber)) {
      $('#reservation_phone').closest('.form-group').addClass('field_with_errors');
      if (!cancel) {
        $("body,html").animate(
          {
            scrollTop: $('#reservation_phone').closest('.form-group').offset().top
          },
          800
        );
      }
      cancel = true;
    } else {
      $('#reservation_phone').closest('.form-group').removeClass('field_with_errors');
    }

    // validate licence plate
    var licencePlate = $('#reservation_licence_plate').val().replace(/ /g, '');
    $('#reservation_licence_plate').val(licencePlate);
    if (licencePlate == "" || !/^([A-Z]|[0-9]){5,7}/.test(licencePlate)) {
      $('#reservation_licence_plate').closest('.form-group').addClass('field_with_errors');
      if (!cancel) {
        $("body,html").animate(
          {
            scrollTop: $('#reservation_licence_plate').closest('.form-group').offset().top
          },
          800
        );
      }
      cancel = true;
    } else {
      $('#reservation_licence_plate').closest('.form-group').removeClass('field_with_errors');
    }

    // validate GDPR consent
    if ($('#gdpr_confirmation').prop("checked") != true) {
      $('#gdpr_confirmation').parent().find('label').css('color','red');
      cancel = true;
    }
    else {
      $('#gdpr_confirmation').parent().find('label').css('color','black');
    }

    if (cancel == true) {
      e.preventDefault();
    } else {
      $('#reserve_form input.submit').val("Odesílání...");
      $('#reserve_form input.submit').prop("disabled", true);
    }
  });
}

$( document ).on('turbolinks:load', initializeReserver);